import { Schedule } from "@mui/icons-material";
import { FlightTrip } from "api/types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  FaCalendarMinus,
  FaCircle,
  FaCircleNotch,
  FaLuggageCart,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaRegDotCircle,
  FaStopwatch,
  FaTiktok,
  FaTimesCircle,
} from "react-icons/fa";
import DurationFormatter from "utils/DurationFormatter";
import parseTime from "utils/parseTime";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import { formatDate } from "utils/formatDate";

interface FlightInfo {
  from: string;
  to: string;
  departureDate: string;
  departureTime: string;
  arrivalDate: string;
  arrivalTime: string;
  cabinClass: string;
  flightDuration: number;
  airline: string;
  airlineLogo: string;
}
interface Flight {
  flight: FlightInfo;
}
const FlightTripDetailPackage: React.FC<Flight> = ({ flight }) => {
  const { t } = useTranslation();

  return (
    <section className="bg-white text-gray-800">

  <div className="grid grid-rows-3 ">
    <div className="flex items-center">
      <FaPlaneDeparture className="text-xl" />
      <span className="text-md font-semibold ml-2">{formatDate(flight.departureDate)}</span>
     {/* <img className="object-contain mr-2" src={flight.airlineLogo} alt="airlineLogo" /> */}
    </div>
    <div className="grid grid-cols-9 items-center gap-1">
      <span className="text-md font-semibold col-span-2 ">{flight.departureTime}</span>
      <span className="rounded-full bg-gray-300 h-3 w-3 col-span-1"></span>
      <span className="col-span-4 text-md">{flight.from}</span>
    </div>
    <div className="grid grid-cols-9 items-center gap-1">
      <span className="col-span-2"></span>
      <div className="flex pl-1 col-span-1">
        <span className="bg-gray-300 w-1 h-5 "></span>
      </div>
      <span className="col-span-4 text-xs text-secondary whitespace-nowrap"> {flight.airline}</span>
    </div>
    <div className="grid grid-cols-9 gap-1 items-center ">
      <span className="text-md font-semibold col-span-2">{flight.arrivalTime}</span>
      <span className="rounded-full bg-gray-300 h-3 w-3 col-span-1"></span>
      <span className="text-md  col-span-4">{flight.to}</span>
    </div>
  </div>

</section>

  );
};
export default FlightTripDetailPackage;
