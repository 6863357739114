import { BookingRoute, BookingTrip, FlightOffer } from "api/types";
import { getDateTime } from "utils/getDateTime";
import { getStaticUrl } from "utils/getStaticUrl";
import CachedIcon from '@mui/icons-material/Cached';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import postFetcher, { postParamRequest } from "api/helpers/postFetcher";
import { Fragment, useEffect, useState } from "react";
import { postReissueValidate } from "api/helpers/api-constants";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import { Route } from "react-router-dom";
import ReissueConfirmation from "./ReissueConfirmation";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import ToastNotification from "components/ui/ToastNotification/ToastNotification";
interface IReissueSummary {
  oldFlight: BookingTrip;
  newFlight: FlightOffer | null |undefined;
  route: BookingRoute;
    bookingKey: string;
    flightTripIndex: number;
}

export const ReissueSummary: React.FC<IReissueSummary> = ({ oldFlight, newFlight, route, bookingKey , flightTripIndex}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validateResponse, setvalidateResponse] = useState<any>(null);
console.log(flightTripIndex)
const [message, setMessage] = useState<string | null>(null);
const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
const resetNotification = () => {
  setMessage(null);
  setSeverity('info');
};
const {t} =useTranslation()
  const reissueValidate = async () => {
    const payload = {
        bookingKey: bookingKey,
        filekey: route.filekey,
        offerId: newFlight?.offerId,
        flightTripIndex: flightTripIndex,
    };
    setIsLoading(true);
    try {
      const response = await postParamRequest(postReissueValidate(), payload);
      if (response && response.status) {
        setMessage(response.message) ;
        setSeverity('error');
      } else if(response) {
      setvalidateResponse(response)
      }
      else
      {
        const correlationID: any = response.headers.get("correlation-id");
        setMessage(correlationID) ;
        setSeverity('error');
      }
    } catch (error) {
        setMessage("error") ;
        setSeverity('error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateSubmit = () => {
    reissueValidate();
  };

  console.log(oldFlight, newFlight);
useEffect(() => {
  console.log("validate")
  setvalidateResponse(null)
}, [newFlight]);

  return (
    <div className="sticky top-0">
      <h1 className="text-2xl p-1 px-4  w-3/5 font-semibold">{t("flight.reissue.summary")}</h1>
      <div className="border p-2 mt-2">
        <h1 className="text-xl p-1 px-4  w-3/5 font-semibold">{t("flight.reissue.oldflight")}</h1>
        {oldFlight.flights.map((flight) => (
          <div key={flight.flightNumber} className="flex justify-between space-x-1 items-center p-2">
           
            <div className="text-md font-semibold lg:text-xl tabular-nums">{flight.flightNumber} {getDateTime(flight.departureDate, 'date')}</div>
              <div className="text-md font-semibold lg:text-xl">{flight.departurePort} {getDateTime(flight.departureDate, 'hour')}</div>
            <ArrowForwardIosIcon />
              <div className="text-md font-semibold lg:text-xl">{flight.arrivalPort} {getDateTime(flight.arrivalDate, 'hour')}</div>
            </div>
        ))}
      </div>
    
      <div className="flex justify-center items-center my-1">
        <CachedIcon />
      </div>
      <div className="border p-2">
        <h1 className="text-xl p-1 px-4  w-3/5 font-semibold">{t("flight.reissue.newflight")}</h1>
        {!newFlight && <span>{t("flight.reissue.pleaseselectnewroute")}</span>}
        {newFlight && newFlight.flightTrips.map((trip) => (
          <div key={trip.flightList[0].flightNumber} className="flex justify-between space-x-1 items-center p-2">
           
            <div className="text-md font-semibold lg:text-xl tabular-nums">{trip.flightList[0].flightNumber} {trip.departureDate}</div>
            <div>
              <div className="text-md font-semibold lg:text-xl">{trip.origin.iataCode}  {trip.departureTime}</div>
            
            </div>
            <ArrowForwardIosIcon />
            <div>
              <div className="text-md font-semibold lg:text-xl">{trip.destination.iataCode} {trip.arrivalTime}</div>
           
            </div>
       
          </div>
          
        ))}
        
        {newFlight && <>
      <span className="text-lg font-semibold">{newFlight.brandName}</span>
      <div className="grid grid-cols-2 gap-4 mr-1 p-1 overscroll-y-auto text-xs bg-white text-black">
        <div>
          {newFlight!.brandFeatureList.slice(0, Math.ceil(newFlight!.brandFeatureList.length / 2)).map((feature, index) => (
            <Fragment key={index}>
              {feature.featureIncluded ? (
                <CheckIcon className="mr-1 text-green-700" />
              ) : (
                <CloseIcon className="mr-1 text-red-700" />
              )}
              <span>
                {feature.quantity ?? feature.quantity}{" "}
                {t("flight.brand.featurelist." + feature.featureType.toLowerCase())}
              </span>
              <small className="block">{feature.featureSubtext}</small>
            </Fragment>
          ))}
        </div>
        <div>
          {newFlight!.brandFeatureList.slice(Math.ceil(newFlight!.brandFeatureList.length / 2)).map((feature, index) => (
            <Fragment key={index}>
              {feature.featureIncluded ? (
                <CheckIcon className="mr-1 text-green-700" />
              ) : (
                <CloseIcon className="mr-1 text-red-700" />
              )}
              <span>
                {feature.quantity ?? feature.quantity}{" "}
                {t("flight.brand.featurelist." + feature.featureType.toLowerCase())}
              </span>
              <small className="block">{feature.featureSubtext}</small>
            </Fragment>
          ))}
        </div>
      </div>
    </>}
      </div>
     
     {newFlight && <div className="flex justify-end p-1">
      <ButtonPrimary onClick={handleValidateSubmit} disabled={validateResponse}>
      <span> <p>{t("flight.brand.checkprice")}</p> 
      <p> {isLoading && <LinearProgress color="primary"/>}</p>
      </span> 
      </ButtonPrimary></div>}       
    { validateResponse &&  <ReissueConfirmation confirmation={validateResponse} />}
    <ToastNotification message={message} severity={severity} resetNotification={resetNotification} />

    </div>
  );
};
