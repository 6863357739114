import React, { FC } from "react";
import {  TourDetails } from "../../../api/types";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { getTourPageUrl } from "../../../utils/navigationLinks";
import currencyFormat from "../../../utils/currencyFormat";
import GallerySlider from "../GallerySlider";
import { useDispatch } from "react-redux";

export interface StayCardTProps {
    className?: string;
    data: TourDetails;
}

export const StayCardTour: FC<StayCardTProps> = ({ className = "", data }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getPrice = () => {
        let priceAmount = data.price || 0;
        return currencyFormat(priceAmount);
    };

    const renderSliderGallery = () => {
        return (
            <div className="relative w-full rounded-t-2xl overflow-hidden will-change-transform">
                <GallerySlider
                    uniqueID={`ExperiencesCard_${data.offerId}`}
                    ratioClass="aspect-w-3 aspect-h-2"
                    galleryImgs={data.thumbnailImage}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className="p-4">
                <div className="space-y-4">
                    <div className="flex items-center justify-start text-neutral-500 dark:text-neutral-400 text-sm space-x-2 h-8">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                        <span className="">{data.regionName}</span>
                    </div>

                    <div className="flex items-start space-x-2 h-24">
                        <h2 className="font-medium capitalize text-base word-wrap ">
                            <span>{data.name}</span>
                        </h2>
                    </div>
                </div>
                <hr className="w-1/1 mx-auto my-4 border-neutral-200" />
                <div className="flex justify-between items-center">
                    <span className="text-base font-semibold">
                        {getPrice()}{` `}
                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                            {t("react.general.total")}
                        </span>
                    </span>
                    
                </div>
                {/* <ButtonPrimary
                    onClick={() => dispatch(addToChart({ type: 'Tour', product: data }))}
                    disabled={false}
                    className="!rounded-lg !py-2 md:ml-4"
                >

                    <FaCartPlus />
                </ButtonPrimary> */}
            </div>
        );
    };

    return (
        <Link
            to={getTourPageUrl(data.offerId)}
            className={`relative overflow-hidden rounded-2xl border border-neutral-100 bg-white transition-shadow hover:shadow-xl dark:border-neutral-800 dark:bg-neutral-900 ${className}}`}
            data-nc-id="stayCardTour"
            target="_blank"
        >
            <div>
                {renderSliderGallery()}
                {renderContent()}
            </div>
        </Link>
    )
}