import React, { useState } from "react";
import GuestsInput, { RoomInput } from "../GuestsInput";
import { FocusedInputShape } from "react-dates";
import DatesRangeInput from "../DatesRangeInput";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { FC } from "react";
import NationalityInput from "../NationalityInput";
import { useNavigate } from "react-router-dom";
import { getHotelPageUrl } from "utils/navigationLinks";
import useRecentSearches from "hooks/useRecentSearches";
import { HotelSearchAutocompleteResult, SearchPayload } from "api/types";
import HotelQueryParametersService from "services/HotelQueryParametersService";
import AutocompleteInput from "../AutocompleteInput";
import { getHotelSearchAutocompleteUrl } from "api/helpers/api-constants";
import { useDispatch } from "react-redux";
import { SetTabSelections } from "redux/slices/tabsSlice";
import { useReduxReducer } from "redux/store";
import { useTranslation } from "react-i18next";
import VillaQueryParameterService from "services/VillaQueryParameterService";

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface HotelSearchFormProps {
  queryParameters: SearchPayload | null;
}

const HotelSearchForm: FC<HotelSearchFormProps> = ({ queryParameters }) => {
  const { appendRecentSearch } = useRecentSearches("recentSearches");

  const [hotel, setHotel] = useState<HotelSearchAutocompleteResult | null>(
    null
  );

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment().add("days", 1),
    endDate: moment().add("days", 2),
  });

  const [nationality, setNationality] = useState("DE");

  const [guests, setGuests] = useState<Array<RoomInput>>([
    { adults: 2, children: [] },
  ]);

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  const [missingInputError, setMissinInputError] = useState({
    nationality: false,
    location: false,
    checkinDate: false,
    checkoutDate: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (queryParameters?.searchType === "VILLA") {
      if (
        queryParameters?.destinationId &&
        queryParameters?.label &&
        queryParameters?.destinationType
      ) {
        setHotel({
          id: queryParameters?.destinationId,
          label: queryParameters?.label,
          type: queryParameters?.destinationType,
        });
      }

      if (queryParameters?.checkinDate && queryParameters?.checkoutDate) {
        setDateRange({
          startDate: moment(queryParameters?.checkinDate),
          endDate: moment(queryParameters?.checkoutDate),
        });
      }

      if (queryParameters?.nationality) {
        setNationality(queryParameters?.nationality);
      }

      if (queryParameters?.roomOccupancies) {
        setGuests(queryParameters?.roomOccupancies);
      }
    }
  }, [JSON.stringify(queryParameters)]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    dispatch(SetTabSelections({ currentTab: "Villa" }));

    setMissinInputError({
      nationality: !nationality,
      location: !hotel,
      checkinDate: !dateRange.startDate,
      checkoutDate: !dateRange.endDate,
    });

    const urlSearchParams = VillaQueryParameterService.encode(
      nationality,
      hotel,
      dateRange,
      guests
    );

    if (urlSearchParams) {
      const recentData = {
        type: "hotel",
        hotel: hotel?.label,
        dateRange: dateRange,
        guests: guests,
        url: urlSearchParams,
      };

      if (hotel!.type === "HOTEL") {
        navigate(getHotelPageUrl(hotel!.id) + "?" + urlSearchParams);
        appendRecentSearch(recentData);
      } else {
        navigate("/search?" + urlSearchParams);
        appendRecentSearch(recentData);
      }
    }
  }
  const { t } = useTranslation();
  const location = t("react.hotel.location");
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="relative w-full rounded-br-lg  bg-white shadow-sm dark:bg-neutral-900 dark:shadow-2xl"
      >
        <div className=" [ nc-divide-field ] flex w-full flex-col rounded-full md:flex-row md:items-center ">
          <div className="[ nc-divide-field ] relative flex flex-grow flex-col md:flex-row ">
           
            <AutocompleteInput
              placeHolder={location}
              blockBorder={true}
              desc={"En uygun Villa fiyatlarını arıyoruz"}
              autocompleteValue={hotel}
              setAutocompleteValue={setHotel}
              localStorageToken="hotel-search-form-autocomplete"
              getUrl={getHotelSearchAutocompleteUrl}
              onInputDone={() => setDateFocused("startDate")}
              error={missingInputError.location}
            />
            <DatesRangeInput
              defaultValue={dateRange}
              defaultFocus={dateFocused}
              checkinLabel={t("app.add-date")}
              checkoutLabel={t("app.add-date")}
              prRanges={false}
              onFocusChange={(focus) => setDateFocused(focus)}
              onChange={(data) => setDateRange(data)}
              checkinDateError={missingInputError.checkinDate}
              checkoutDateError={missingInputError.checkoutDate}
              value={dateRange}
            />
            <GuestsInput guestValue={guests} setGuestValue={setGuests} />
            <NationalityInput
              value={nationality}
              setValue={setNationality}
              error={missingInputError.nationality}
            />
            {/* BUTTON SUBMIT OF FORM */}
            <div className="justify center flex items-center px-4 py-4 lg:py-0">
              <SubmitButton />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HotelSearchForm;
