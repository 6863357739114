import React from 'react';
import currencyFormat from 'utils/currencyFormat';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { formatDate } from 'utils/formatDate';
import FlightQueryParametersService from 'services/FlightQueryParametersService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BannerCardFlight } from 'api/types';

export const FlightBannerCard: React.FC<BannerCardFlight> = (banner: BannerCardFlight) => {
const navigate = useNavigate()  
const HandleNavigateSearch = () => {
  const urlSearchParams = FlightQueryParametersService.encode(
    "Oneway",
   { adults: 1,
    children: 0,
    infants: 0,
  },
    "ECONOMY",
    {id: banner.fromLocationCode,
    text: banner.fromLocationName},
    {
      id:banner.toLocationCode,
      text:banner.toLocationName
    },
    [
      {
          departureAirport: null,
          arrivalAirport: null,
          departureDate: null,
      },
      {
          departureAirport: null,
          arrivalAirport: null,
          departureDate: null,
      }
  ],
    {
        startDate:  null,
        endDate:  null,
    },
    moment(banner.departureDate) 
);
if(urlSearchParams)
navigate("/flight-search?" + urlSearchParams);
}
 
  return (
    <div
    onClick={HandleNavigateSearch}
    className="bg-white rounded-lg shadow-md overflow-hidden transition duration-200 ease hover:shadow-2xl hover:cursor-pointer">
      <figure className="w-full">
        <div className="relative h-2/3">
          <img src={banner.imageUrl} alt={""} className=" object-cover h-48  w-full" />
        </div>
      </figure>
        <div className="grid grid-rows-2 p-2">
      <div className='flex justify-between gap-1 text-md font-semibold items-center text-gray-900 '>
        <div> <span> {banner.fromLocationName}</span>
        <KeyboardDoubleArrowRightIcon/>
        <span>{banner.toLocationName}</span></div>
        <div><img width="20" height="20" className="" src={banner.airline?.logoUrl} alt=""/></div>    
       </div>
      <div className='flex justify-between text-sm '> 
      <div>  {formatDate(banner.departureDate)}</div>
        <div className="text-primary-6000 font-semibold text-xl">{currencyFormat(banner.price) }</div>
      </div>
        </div>
  </div>
  );
};




