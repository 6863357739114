import React, { FC } from "react";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/common/HeroSearchForm";
import {isB2B} from "../../../api/helpers/checkUserRole";
import SearchEngine from "../SearchEngines/SearchEngine";
import {useReduxReducer} from "../../../redux/store";

export interface SectionHeroProps {
  className?: string;
}

const _isB2B = isB2B();

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const { isMobile, isApp } = useReduxReducer(state => state.general)

  return (
    <div
      className={`nc-SectionHero relative flex flex-col ${className}`}
      data-nc-id="SectionHero"
    >
      {/*
        _isB2B ?
            null
            :
            <div className="flex flex-col lg:flex-row lg:items-center">
              <div className="flex flex-shrink-0 flex-col items-start space-y-8 pb-14 sm:space-y-10 lg:mr-10 lg:w-1/2 lg:pb-64 xl:mr-0 xl:pr-14">
                <h2 className="text-4xl font-medium !leading-[114%] md:text-5xl xl:text-7xl ">
                  Hotel, car & experiences
                </h2>
                <span className="text-base text-neutral-500 dark:text-neutral-400 md:text-lg">
                  Accompanying us, you have a trip full of experiences. With ATR,
                  booking accommodation, resort villas, hotels
                </span>
                <ButtonPrimary>Start your search</ButtonPrimary>
              </div>
              <div className="flex-grow">
                <img className="w-full" src={imagePng} alt="hero" />
              </div>
            </div>
      */}

      <div className={`${isMobile ? !isApp && 'mt-3' : 'mt-5'} z-10 w-full`}>
        <HeroSearchForm />
       { /*<SearchEngine />*/}
      </div>
    </div>
  );
};

export default SectionHero;
