import React, { Suspense } from "react";
import MyRouter from "routers/index";
import 'tippy.js/dist/tippy.css'; // optional
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-dates/initialize";
import { Provider } from "react-redux";
import { store } from "redux/store";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true });
    console.error(error, errorInfo);
    // Redirect and refresh the page eg. chunk load error or some crashes
    window.location.href = process.env.PUBLIC_URL;
    // Refresh the page after a timeout
    setTimeout(() => {
      window.location.reload();
    }, 1000);
   
     
  }
  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return <></>;
    }
    return this.props.children;
  }
}

function App() {
  return (
    <Provider store={store}>
      <div className="bg-customGray text-lg text-neutral-900 dark:bg-neutral-900 dark:text-neutral-200">
        <ErrorBoundary>
          <Suspense fallback={<></>}>
            <MyRouter />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Provider>
  );
}

export default App;
