import SectionHero from "components/common/SectionHero";
import React, {useEffect} from "react";
import BgGlassmorphism from "components/common/BgGlassmorphism";
import { Helmet } from "react-helmet";
import PageImages from "./PageImages";
import { useReduxReducer } from "redux/store";
import SuggestedTours from "components/common/HeroSearchForm/TourSearchForm/SuggestedTours";
import SearchGrid from "pages/TourSearchPage/SearchGrid";
import BestOffers from "./BestOffers";
import RecentSearches from "./RecentSearches";
import useQueryParameters from "../../hooks/useQueryParameters";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SetTabSelections} from "../../redux/slices/tabsSlice";
import { BannerB2C } from "pages/BannerB2C/BannerB2C";
import AdvertisementSection from "./AdvertisementSection";



function PageHome() {

    const dispatch = useDispatch()
    const { isApp, isMobile } = useReduxReducer(state => state.general)
    const {isAdmin} = useReduxReducer(state=> state.general)
    const {audit} = useReduxReducer(state => state.config)
  const tabsSelection  = useReduxReducer(state => state.tabs)

    let { type } = useParams();

    useEffect(() => {
        if (isApp && type)
            dispatch(SetTabSelections(type))
    }, [type])
    const {meta,header} = useReduxReducer((state)  => state.config)

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>
         {meta.title}
        </title>
        <link rel="icon" type="image/png" href={header.favicon} sizes="16x16" />
        <meta name="description" content={meta.description} />
        <link rel="apple-touch-icon" href={header.defaultLogo} />


      </Helmet>

      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className={`${!isApp && 'container relative mb-24 space-y-12 lg:mb-32'}`}>
        {/* SECTION HERO */}
        <SectionHero />

        {!isApp && (tabsSelection.activeTab === "Tour" 
        ? <SuggestedTours /> 
        : <>
        {/*audit.marketingType === "B2B" && !isMobile && <AdvertisementSection/>*/}
        {audit.marketingType === "B2B" && <RecentSearches />}
        <BestOffers/>
        <BannerB2C/>
        </>)}
      </div>
    </div>
  );
}

export default PageHome;
