import React, { Dispatch, useEffect, useState } from "react";
import { SingleDatePicker } from "react-dates";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import useWindowSize from "hooks/useWindowResize";
import moment, { Moment } from "moment";
import { CalendarIcon, ClockIcon } from "@heroicons/react/outline";
import timeSelectOptions from "utils/timeSelectOptions";
import HeroSelect from "./HeroSelect";
import {b2cURL, getBestPricesUrl} from "api/helpers/api-constants";
import { AirportSearchAutocompleteResult, BestPricesModel } from "api/types";
import { BsChevronRight } from "react-icons/bs";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { fetcherWithoutJSON } from "api/helpers/fetcher";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useReduxReducer } from "redux/store";

export interface DateInputProps {
  className?: string;
  date: Moment | null;
  setDate: (date: moment.Moment | null) => void;
  fieldClassName?: string;
  wrapClassName?: string;
  time?: string | null;
  setTime?: React.Dispatch<React.SetStateAction<string | null>>;
  label?: string;
  Icon?: React.ReactNode & FC<React.ComponentProps<"svg">>;
  minDate?: Moment;
  maxDate?: Moment;
  error?: boolean;
  setTimeFocus?: (val: boolean) => void;
  numberOfMonths?: number;
  dateFocus?: boolean;
  setDateFocus?: Dispatch<React.SetStateAction<boolean>>;
  departureAirport?: AirportSearchAutocompleteResult | null;
  arrivalAirport?: AirportSearchAutocompleteResult | null;
  setNextFocus?: Dispatch<React.SetStateAction<boolean>>;
}

const DateInput: FC<DateInputProps> = ({
  className,
  date,
  setDate,
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapClassName = "divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 lg:border-none",
  time,
  setTime,
  label = "Check In",
  Icon = CalendarIcon,
  minDate = moment().subtract(1, "day"),
  maxDate = moment().add(1, "year"),
  error,
  setTimeFocus,
  numberOfMonths = 1,
  dateFocus,
  setDateFocus,
  departureAirport,
  arrivalAirport,
  setNextFocus,
}) => {
  const { isB2C } = useReduxReducer(state => state.general)

  const [focused, setFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMultiRoute, setIsMultiRoute] = useState(false);

  const [selectedRoute, setSelectedRoute] = useState<string | null>(null);
  const [routeOptions, setRouteOptions] = useState<string[]>([]);
  const {serviceFee} = useReduxReducer((state) => state.flight)
  const [bestPrices, setBestPrices] = useState<BestPricesModel[]>([]);
  const [dateRange, setDateRange] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({
    startDate: date ? moment(date).startOf("month") : moment(),
    endDate: date
      ? moment(date).add(1, "month").endOf("month")
      : moment().add(1, "month").endOf("month"),
  });
  const [directFlight, setDirectFlight] = useState<boolean>(false);

  const { t } = useTranslation();
  const windowSize = useWindowSize();

  useEffect(() => {
    setSelectedRoute(null);

    if (departureAirport?.id && arrivalAirport?.id) {
      const depAirports = departureAirport.id.split(",");
      const arrAirports = arrivalAirport.id.split(",");

      const options: string[] = [];

      depAirports.forEach((depAirport) => {
        arrAirports.forEach((arrAirport) => {
          options.push(`${depAirport} - ${arrAirport}`);
        });
      });
      setRouteOptions(options);
      selectedRoute === null && setSelectedRoute(options[0]);
    }
  }, [departureAirport, arrivalAirport]);

  const handleFocusChange = ({ focused }: { focused: boolean }) => {
    setFocused(focused);
    setDateFocus && setDateFocus(focused);
  };

  useEffect(() => {
    if (dateFocus) {
      setFocused(true);
    }
  }, [dateFocus]);

  const handleClearData = () => {
    setDate(null);
    setFocused(false);
    setTimeFocus && setTimeFocus(false);
  };

  const getPrices = async (
    from: string,
    to: string,
    startDate: string,
    endDate: string
  ) => {
    setIsLoading(true);

   if (from.includes(",") || to.includes(",")) {
    setIsMultiRoute(true);
    const tempRoute = selectedRoute?.split(" - ");
    from = tempRoute?.[0] || from;
    to = tempRoute?.[1] || to;
  } else {
    setIsMultiRoute(false);
  }

    const url = `${isB2C ? b2cURL : ''}${getBestPricesUrl(from, to, startDate, endDate)}`;

    await fetcherWithoutJSON(url)
      .then((res) => res.json())
      .then((data) => {
        if (data.status !== "error") {
          let newData = data.map((item: BestPricesModel) => {
            return {
              ...item,
              isBestPrice: false,
            };
          });

          let bestPrices: { first: number[]; last: number[] } = {
            first: [],
            last: [],
          };

          if (directFlight) {
            newData = newData.filter(
              (item: BestPricesModel) => item.nonStopPrice
            );

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month").subtract(1, "day")
                )
              ) {
                if (moment(element.departureDate).isAfter(moment())) {
                  if (element.nonStopPrice) {
                    bestPrices.first.push(element.nonStopPrice);
                  }
                }
              } else if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
                  dateRange.endDate
                )
              ) {
                if (moment(element.departureDate).isAfter(moment())) {
                  if (element.nonStopPrice) {
                    bestPrices.last.push(element.nonStopPrice);
                  }
                }
              }
            });

            bestPrices.first.sort((a, b) => a - b);
            bestPrices.last.sort((a, b) => a - b);

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month")
                )
              ) {
                if (element.nonStopPrice) {
                  if (element.nonStopPrice === bestPrices.first[0]) {
                    element.isBestPrice = true;
                  }
                }
              } else if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month"),
                  dateRange.endDate
                )
              ) {
                if (element.nonStopPrice) {
                  if (element.nonStopPrice === bestPrices.last[0]) {
                    element.isBestPrice = true;
                  }
                }
              } else {
                element.isBestPrice = false;
              }
            });
          } else {
            // newData = newData.filter((item: BestPricesModel) => item.nonStopPrice)

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month").subtract(1, "day")
                )
              ) {
                bestPrices.first.push(element.bestPrice ?? 0);
              }

              if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
                  dateRange.endDate
                )
              ) {
                bestPrices.last.push(element.bestPrice ?? 0);
              }
            });

            bestPrices.first.sort((a, b) => a - b);
            bestPrices.last.sort((a, b) => a - b);

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month")
                )
              ) {
                if (element.bestPrice === bestPrices.first[0]) {
                  element.isBestPrice = true;
                }
              } else if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month"),
                  dateRange.endDate
                )
              ) {
                if (
                  Math.ceil(element.bestPrice ?? 0) ===
                  Math.ceil(bestPrices.last[0])
                ) {
                  element.isBestPrice = true;
                }
              } else {
                element.isBestPrice = false;
              }
            });
          }
          setBestPrices(newData);
        }
      })
      .catch((err) => {
        console.error("err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let newData = bestPrices.map((item: BestPricesModel) => {
      return {
        ...item,
        isBestPrice: false,
      };
    });

    let prices: { first: number[]; last: number[] } = { first: [], last: [] };

    if (directFlight) {
      newData = newData.filter((item: BestPricesModel) => item.nonStopPrice);

      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month").subtract(1, "day")
          )
        ) {
          if (moment(element.departureDate).isAfter(moment())) {
            if (element.nonStopPrice) {
              prices.first.push(element.nonStopPrice);
            }
          }
        } else if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
            dateRange.endDate
          )
        ) {
          if (moment(element.departureDate).isAfter(moment())) {
            if (element.nonStopPrice) {
              prices.last.push(element.nonStopPrice);
            }
          }
        }
      });

      prices.first.sort((a, b) => a - b);
      prices.last.sort((a, b) => a - b);

      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month")
          )
        ) {
          if (element.nonStopPrice) {
            if (element.nonStopPrice === prices.first[0]) {
              element.isBestPrice = true;
            }
          }
        } else if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month"),
            dateRange.endDate
          )
        ) {
          if (element.nonStopPrice) {
            if (element.nonStopPrice === prices.last[0]) {
              element.isBestPrice = true;
            }
          }
        } else {
          element.isBestPrice = false;
        }
      });
    } else {
      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month").subtract(1, "day")
          )
        ) {
          if (element.bestPrice) {
            prices.first.push(element.bestPrice);
          }
        }

        if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
            dateRange.endDate
          )
        ) {
          if (element.bestPrice) {
            prices.last.push(element.bestPrice);
          }
        }
      });

      prices.first.sort((a, b) => a - b);
      prices.last.sort((a, b) => a - b);

      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month")
          )
        ) {
          if (element.bestPrice === prices.first[0]) {
            element.isBestPrice = true;
          }
        } else if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month"),
            dateRange.endDate
          )
        ) {
          if (element.bestPrice === prices.last[0]) {
            element.isBestPrice = true;
          }
        } else {
          element.isBestPrice = false;
        }
      });
    }

    setBestPrices(newData);
  }, [directFlight]);

  const renderDayContent = (day: moment.Moment) => {
    let _ = bestPrices.find(
      (item) =>
        moment(item.departureDate).format("YYYY-MM-DD") ===
        day.format("YYYY-MM-DD")
    );

    if (_) {
      return (
        <div className="flex flex-col items-center">
          <div
            className={`${
              day.format("DD.MM.YYYY") === date?.format("DD.MM.YYYY")
                ? "!text-white"
                : moment(day).isBefore(minDate)
                ? "!text-gray-300"
                : "!text-black"
            }`}
          >
            {day.format("D")}
          </div>
          <div
            className={`text-xs ${
              day.format("DD.MM.YYYY") === date?.format("DD.MM.YYYY")
                ? "!text-gray-100"
                : moment(day).isBefore(minDate)
                ? "!text-gray-300"
                : directFlight && _.nonStopPrice
                ? !_.isBestPrice
                  ? "!text-primary-6000"
                  : ""
                : "!text-gray-600"
            } ${directFlight && !_.nonStopPrice && "!hidden"} ${
              moment(day).isBefore(minDate) && "invisible"
            } ${_.isBestPrice && "font-semibold !text-[#ff690f]"}`}
          >
            {directFlight
              ? _.nonStopPrice && Math.ceil(_.nonStopPrice) + serviceFee
              : Math.ceil(_.bestPrice ?? 0) + serviceFee }{" "}
            €
          </div>
        </div>
      );
    }
    return (
      <div
        className={`${
          day.format("DD.MM.YYYY") === date?.format("DD.MM.YYYY")
            ? "!text-white"
            : moment(day).isBefore(minDate)
            ? "!text-gray-300"
            : "!text-black"
        }`}
      >
        {day.format("D")}
      </div>
    );
  };

  useEffect(() => {
    if (
      departureAirport?.id!.length &&
      arrivalAirport?.id!.length
    ) {
      getPrices(
        departureAirport.id,
        arrivalAirport.id,
       moment().format("DD.MM.YYYY"),
       moment().add(6,"month").format("DD.MM.YYYY"),
            );
    }
  }, [
    selectedRoute,
  ]);

  // useEffect(() => {
  //   if (departureAirport && arrivalAirport) {
  //     if (date && moment().month() === date.month()) {
  //       setDateRange({
  //         startDate: moment().startOf("month"),
  //         endDate: moment().add(1, "month").endOf("month"),
  //       });
  //     } else if (date && moment().month() !== date.month()) {
  //       setDateRange({
  //         startDate: moment(date).startOf("month"),
  //         endDate: moment(date).add(1, "month").endOf("month"),
  //       });
  //     } else if (!date) {
  //       setDateRange({
  //         startDate: moment().startOf("month"),
  //         endDate: moment().add(1, "month").endOf("month"),
  //       });
  //     }
  //   }
  // }, [date, focused, departureAirport, arrivalAirport]);

  const renderInputCheckOutDate = () => {
    const errorClassName = error ? "text-red-400" : "";

    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} flex-shrink-0 cursor-pointer items-center space-x-3 ${
          focused ? "rounded-2xl dark:bg-neutral-800" : " "
        }`}
        onClick={() => setFocused(true)}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <Icon className="nc-icon-field" />
        </div>
        <div className="flex-grow">
          <span className="xl:text-base block font-semibold">
            {date ? date.format("DD MMM YYYY") : label}
          </span>
          <span
            className={
              "block text-sm font-medium leading-none text-neutral-400 " +
              errorClassName
            }
          >
            {date ? label : `${t("app.add-date")}`}
          </span>
          {date && focused && (
            <ClearDataButton onClick={() => handleClearData()} />
          )}
        </div>
      </div>
    );
  };

  const isOutsideRange =
    minDate && maxDate
      ? (day: moment.Moment) => {
          return minDate.isAfter(day) || maxDate.isBefore(day);
        }
      : undefined;

  return (
    <div
      className={`[ lg:nc-flex-2 ] relative flex flex-shrink-0 ${className}`}
    >
      <div className="absolute inset-x-0 bottom-0">
        <SingleDatePicker
          id="single-date-picker"
          date={date}
          initialVisibleMonth={() => moment(minDate ? minDate : dateRange.startDate)}
          onDateChange={(date: Moment | null) => {
            setDate(date);
            setTimeFocus && setTimeFocus(true);
          }}
          focused={focused}
          isOutsideRange={isOutsideRange}
          onFocusChange={handleFocusChange}
          numberOfMonths={numberOfMonths ?? (windowSize.width <= 1024 && 1)}
          daySize={windowSize.width > 500 ? 56 : undefined}
          orientation={"horizontal"}
          onNextMonthClick={() => {
            setDateRange({
              startDate: dateRange.startDate.add(1, "month"),
              endDate: dateRange.endDate.add(1, "month"),
            });
          }}
          onPrevMonthClick={() => {
            setDateRange({
              startDate: dateRange.startDate.subtract(1, "month"),
              endDate: dateRange.endDate.subtract(1, "month"),
            });
          }}
          noBorder
          hideKeyboardShortcutsPanel
          firstDayOfWeek={1}
          renderDayContents={(day) =>
            isLoading ? (
              <div className="flex flex-col items-center">
                <div
                  className={`${
                    day.format("DD.MM.YYYY") === date?.format("DD.MM.YYYY")
                      ? "!text-white"
                      : moment(day).isBefore(minDate)
                      ? "!text-gray-200"
                      : "!text-black"
                  }`}
                >
                  {day.format("D")}
                </div>
                <div className="h-2 w-6 animate-pulse rounded-full bg-slate-200"></div>
              </div>
            ) : (
              renderDayContent(day)
            )
          }
          calendarInfoPosition="top"
          renderCalendarInfo={() => {
            if (departureAirport?.id && arrivalAirport?.id) {
              return (
                <div className="grid w-full grid-cols-2 items-center justify-between pt-2 px-2">
                  <span>
                    <div className="flex items-center">
                      <Checkbox
                        name={"direct-flights"}
                        label={
                          <span className="flex flex-col">
                            <p>
                              {t(
                                "b2c-app.flights.results.filter.stops.direct-flight"
                              )}
                            </p>

                          </span>
                        }
                        checked={directFlight}
                        labelClassName="text-sm text-neutral-500"
                        inputClassName="my-auto"
                        onChange={(e) => setDirectFlight(e)}
                      />
                    </div>
                  </span>
                  <div className="flex items-center justify-center space-x-2 pt-3 pl-3 font-semibold">
                    <span>{departureAirport.id}</span>
                    <BsChevronRight />
                    <span>{arrivalAirport.id}</span>
                  </div>
                  <div></div>
                  {isMultiRoute ? (
                    <div
                      className="row mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gridColumn: "span 2",
                      }}
                    >
                      {routeOptions.map((option) => (
                        <span key={option} style={{ margin: "0 4px" }}>
                          <Chip
                            key={option}
                            label={option}
                            size={"small"}
                            variant={
                              selectedRoute === option ? "filled" : "outlined"
                            }
                            color={
                              selectedRoute === option ? "primary" : "default"
                            }
                            onClick={() => setSelectedRoute(option)}
                          />
                        </span>
                      ))}
                    </div>
                  ) : null}
                </div>
              );
            } else {
              return undefined;
            }
          }}
        />
      </div>

      <div
        className={`relative flex w-full flex-shrink-0 flex-col lg:flex-row lg:items-center  ${wrapClassName}`}
      >
        {renderInputCheckOutDate()}
        {time && setTime && (
          <HeroSelect
            Icon={ClockIcon}
            selectOptions={timeSelectOptions}
            value={time}
            setValue={setTime}
          />
        )}
      </div>
    </div>
  );
};

export default DateInput;
