import React, {FC, useEffect, useState} from "react"
import {ISolution} from "./AvatarDropdown";
import {IUserData, IUserMetaData} from "../../../api/types";
import {
    getAgencyAdminSettingsUrl,
    getAgencyExpiTransactionUrl, getAgencyPrePaymentWalletUrl, getLogoutUrl, getUserDataUrl, getUserMetaDataUrl,
} from "../../../api/helpers/api-constants";
import {CogIcon, CurrencyDollarIcon, GiftIcon, LogoutIcon} from "@heroicons/react/outline";
import {FaUserAlt} from "react-icons/fa";
import {useReduxReducer} from "../../../redux/store";
import fetcher from "../../../api/helpers/fetcher";
import {useDispatch} from "react-redux";
import {setUserData} from "../../../redux/slices/generalSlice";
import { useTranslation } from "react-i18next";

interface IUserInformation {}

const userCardFooter =
    {
        name: "Logout",
        href: getLogoutUrl(),
        icon: LogoutIcon,
    };

export const UserInformation: FC<IUserInformation> = () => {
    const dispatch = useDispatch()
    const { isMobile, isApp, userData } = useReduxReducer(state => state.general)
    const {t} = useTranslation()
       const [solutions, setSolutions] = useState<ISolution[]>();

    const avatarClickHandler = async () => {
        const iUserMetaDataResponse: IUserMetaData = await fetcher(getUserMetaDataUrl());

        if (userData === null) {
            const userDataResponse: IUserData = await fetcher(getUserDataUrl());
            dispatch(setUserData(userDataResponse))
        }

        const _solutions: ISolution[] = [
            {
                name: t("agency-settings.settings"),
                color: "",
                href: getAgencyAdminSettingsUrl(),
                icon: CogIcon,
            },
            {
                name: "Expi: " + iUserMetaDataResponse.expiTotalPoints,
                color: "",
                href: getAgencyExpiTransactionUrl("expiUserId=" + iUserMetaDataResponse.userId),
                icon: GiftIcon,
            },
            {
                name: "Sepa: " + iUserMetaDataResponse.agencyBalance + " €",
                color: iUserMetaDataResponse.agencyBalance > 0 ? "text-green-500" : "text-red-500",
                href: "",
                icon: CurrencyDollarIcon,
            },
            {
                name: t("wallet.balance") +": " + iUserMetaDataResponse.walletBalance + " €",
                color: Number.parseFloat(iUserMetaDataResponse.walletBalance.replace(",", ".")) > 0 ? "text-green-500" : "text-red-500",
                href:"#/invoice",
                icon: CurrencyDollarIcon,
            }
        ]

        setSolutions(_solutions);
    }

    useEffect(() => {
        avatarClickHandler()
    }, [])

    return (
        <>
            <div className={(isMobile || isApp) ? "" : "overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5"}>
                <div className="relative flex items-center bg-white dark:bg-neutral-800 p-3">
                    <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 bg-primary-50 rounded-md text-primary-500 text-4xl">
                        <FaUserAlt aria-hidden="true" />
                    </div>
                    <div className="px-2 space-y-0.5">
                        <p className="text-sm font-medium ">{userData?.nickname}</p>
                        <p className="text-xs text-neutral-500 dark:text-neutral-300">
                            {userData?.email}
                        </p>
                    </div>
                </div>
                <div className="relative grid gap-6 bg-white p-7 pt-2 dark:bg-neutral-800">
                    {solutions?.filter(item => isApp ? item.name !== "Einstellungen" : true).map((item, index) => (
                        item.href.length > 0 ? (
                            <a
                                key={index}
                                style={item.color.length > 0 ? { color: "#22c55e" } : {}}
                                href={item.href}
                                target={(isMobile || isApp) ? "_self" : "_blank"}
                                className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                                <div className="flex flex-shrink-0 items-center justify-center">
                                    <item.icon aria-hidden="true" className="h-6 w-6" />
                                </div>
                                <div className="ml-4">
                                    <p className="text-base font-medium ">{item.name}</p>
                                </div>
                            </a>
                        ) : (
                            <a
                                key={index}
                                style={{ color: "#e04040" }}
                                className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                                <div className="flex flex-shrink-0 items-center justify-center">
                                    <item.icon aria-hidden="true" className="h-6 w-6" />
                                </div>
                                <div className="ml-4">
                                    <p className="text-base font-medium ">{item.name}</p>
                                </div>
                            </a>
                        )
                    ))}
                </div>
                {
                    !(isMobile || isApp) && (
                        <div className="relative grid gap-6 bg-white p-7 dark:bg-neutral-800 border-t">
                            <a
                                href={userCardFooter.href}
                                onClick={() => setUserData(null)}
                                className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 dark:hover:bg-gray-700"
                            >
                                <div className="flex flex-shrink-0 items-center justify-center text-neutral-500 dark:text-neutral-300">
                                    <userCardFooter.icon aria-hidden="true" className="h-6 w-6" />
                                </div>
                                <div className="ml-4">
                                    <p className="text-base font-medium ">{userCardFooter.name}</p>
                                </div>
                            </a>
                        </div>
                    )
                }
            </div>
        </>
    )
}
