import React from "react";
import NcInputNumber from "components/common/NcInputNumber";
import NcInputNumberAdult from "components/common/NcInputNumberAdult";
import { FC } from "react";
import Modal from "components/ui/Modal";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { UserAddIcon } from "@heroicons/react/outline";
export interface TravelersInputValue {
  adults: number;
  children: number;
  infants: number;
}

export interface TravelersInputProps {
  travelers: TravelersInputValue;
  setTravelers: (value: TravelersInputValue) => void;
  iconClassName?: string;
  labelClassName?: string;
  subtitleClassName?: string;
  subtitle?: boolean;
  className?: string;
}

const TravelersInput: FC<TravelersInputProps> = ({
  travelers,
  setTravelers,
  iconClassName = "",
  labelClassName = "",
  subtitleClassName = "",
  className = "",
  subtitle = true,
}) => {
  const { t } = useTranslation();

  const numOfTravelers =
    travelers.adults + travelers.children + travelers.infants;
  return (
    <Modal
      contentExtraClass="max-w-screen-md"
      modalTitle={t("react.hotel.travellers")}
      renderTrigger={(openModal) => (
        <div
          onClick={() => openModal()}
          className={`nc-hero-field-padding flex w-full flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none ${className} `}
        >
          <div>
            <UserAddIcon
              className={cn(
                "nc-icon-field mb-1 text-neutral-300 dark:text-neutral-400",
                iconClassName
              )}
            />
          </div>
          <div className="flex-grow">
            <span
              className={cn(
                "xl:text-lg block whitespace-nowrap font-bold",
                labelClassName
              )}
            >
              {numOfTravelers || ""} {t("b2c-app.flights.traveler")}
            </span>
            {subtitle && (
              <span
                className={cn(
                  "text-sm mt-1 block font-light leading-none text-neutral-400",
                  subtitleClassName
                )}
              >
                {numOfTravelers ? "Travelers" : "Add travelers"}
              </span>
            )}
          </div>
        </div>
      )}
      renderContent={() => (
        <TravelersInputModal
          travelers={travelers}
          setTravelers={setTravelers}
        />
      )}
    />
  );
};

type TravelersInputModalProps = TravelersInputProps & {};

const TravelersInputModal: React.FC<TravelersInputModalProps> = ({
  travelers,
  setTravelers,
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div>
        <NcInputNumberAdult
          defaultValue={travelers.adults}
          onChange={(adults) => setTravelers({ ...travelers, adults })}
          label={t("generals.adult")}
        />
      </div>
      <div>
        <NcInputNumber
          defaultValue={travelers.children}
          onChange={(children) => setTravelers({ ...travelers, children })}
          label={t("generals.child")}
        />
      </div>
      <div>
        <NcInputNumber
          defaultValue={travelers.infants}
          onChange={(infants) => setTravelers({ ...travelers, infants })}
          label={t("generals.infant")}
        />
      </div>
    </div>
  );
};

export default TravelersInput;
