import React, { useState } from 'react';
import Swal from 'sweetalert2';
import ButtonPrimary from 'components/ui/Button/ButtonPrimary';
import ConfirmationDialog from 'components/ui/ConfirmationDialog/ConfirmationDialog';
import { postParamRequest } from 'api/helpers/postFetcher';
import { postReissueConfirm } from 'api/helpers/api-constants';
import { LinearProgress } from '@material-ui/core';
import currencyFormat from 'utils/currencyFormat';
import { useTranslation } from 'react-i18next';
import ToastNotification from 'components/ui/ToastNotification/ToastNotification';

interface ConfirmationSwalProps {
  confirmation: {
    amount: number;
    currencyCode: string;
    key: string;
  };
}

const ReissueConfirmation: React.FC<ConfirmationSwalProps> = ({ confirmation }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
    const resetNotification = () => {
      setMessage(null);
      setSeverity('info');
    };
    const {t} = useTranslation()
    const reissueConfirm = async () => {
      const payload = {
         key: confirmation.key
      };
      setIsLoading(true);
      try {
        const response = await postParamRequest(postReissueConfirm(), payload);
        if (response.success === false) {
          const correlationID: any = response.headers.get("correlation-id");
          setMessage(response.message);
          setSeverity('error');
        } else {
          setMessage(t("generals.success"));
          setSeverity('success');
        }
      } catch (error) {
        console.error(error);
        setMessage("Error");
        setSeverity('error');
      } finally {
        setIsLoading(false);
      }
    };
    const handleConfirm = () => {
      console.log("Confirmed!");
     reissueConfirm()
    };
  
    const handleButtonClick = () => {
      setIsDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };
  return (
    <div>
         <div className="border p-2 mt-2">
        <h1 className="text-xl p-1 px-4 w-3/5 font-semibold">{t("flight.reissue.pricesummary")}</h1>
        <p>{t("flightbooking.reissue.total-amount-to-be-paid-for-all-passengers")}</p>
        <p className='font-semibold'> {currencyFormat(confirmation.amount) }</p>

        <div className='flex justify-end'><ButtonPrimary  onClick={handleButtonClick} disabled={!confirmation.key}>
        {t("b2c-app.confirmation-request.confirm")}
        {isLoading && <LinearProgress/>}
      </ButtonPrimary></div>
        </div>
      
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
      <ToastNotification message={message} severity={severity} resetNotification={resetNotification} />

    </div>
  );
};

export default ReissueConfirmation;
