import { getTourSearchResultsUrl } from "api/helpers/api-constants";
import usePostRequest from "api/hooks/usePostRequest";
import { SearchPayload, TourSearchResults } from "api/types";
import Heading2 from "components/common/Heading/Heading2";
import useTourResultFilter from "hooks/filters/useTourResultFilter";
import moment from "moment";
import React, { useEffect } from "react";
import { setDataFilters } from "redux/slices/filtersSlice";
import { useReduxReducer, useSearchPageDispatch } from "redux/store";
import { usePagination } from "react-use-pagination";
import ProgressBar from "components/ui/ProgressBar/ProgressBar";
import { StayCardTour } from "components/common/StayCardTour";
import Pagination from "components/ui/Pagination/Pagination";
import { useTranslation } from "react-i18next";


const PAGE_SIZE = 12;

const SuggestedTours = () => {

    const queryParameters: SearchPayload = {
        searchType: "Tour",
        label: "Tümü",
        afterDate: moment().add(5, "days").format("YYYY-MM-DD"),
        beforeDate: moment().add(20, "days").format("YYYY-MM-DD"),
        destinationId: "100000",
        totalGuests: 2,
        roomOccupancies: [
            {
                adults: 2,
                children: [],
            },
        ],
    }

    const { data, isLoading } = usePostRequest<TourSearchResults>({
        searchUrl: getTourSearchResultsUrl,
        payload: queryParameters
    });

    const { t } = useTranslation();
    const results = useTourResultFilter(data, queryParameters?.totalGuests);
    const dispatch = useSearchPageDispatch();

    useEffect(() => {
        if (data && data.filter) dispatch(setDataFilters(data.filter));
    }, [JSON.stringify(data?.filter)]);

    const activeFilters = useReduxReducer(
        (state) => state.filters.activeFilters
    );

    useEffect(() => {
        setPage(0);
    }, [JSON.stringify(activeFilters)]);

    const {
        startIndex,
        endIndex,
        currentPage,
        setPage,
        setPreviousPage,
        setNextPage,
        previousEnabled,
        nextEnabled,
    } = usePagination({
        totalItems: data?.tours?.length ? data?.tours?.length + 1 : 0,
        initialPageSize: PAGE_SIZE,
    });

    const resultToShow = results.slice(startIndex, endIndex + 1) || [];

    useEffect(() => {
        if (queryParameters) {
            setTimeout(() => {
                document.getElementsByClassName("nc-HeroSearchForm")[0].scrollIntoView({ behavior: "smooth" });
            }, 200);
        }
    }, [JSON.stringify(queryParameters)]);

    if (!queryParameters) return null;


    // const {data, isLoading} = usePostRequest<TourSearchResults>({
    //     searchUrl: getTourSearchResultsUrl,
    //     payload: queryParameters
    // });


    return (
        <div className="mb-12 max-w-[1280px] mx-auto pb-8 dark:bg-neutral-800">
            <div className="mb-8 space-y-8 lg:mb-11">
                <Heading2
                    numStays={data?.tours?.length || 0}
                    heading={queryParameters?.label}
                />
                {isLoading && <ProgressBar progress={data?.finished || 5} />}
            </div>
            {
                results.length > 0 ?
                    <>
                        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                            {resultToShow.map((item) => (
                                <StayCardTour
                                    key={item.id}
                                    data={item}
                                />
                            ))}
                        </div>
                        <div className="mt-16 flex items-center justify-center">
                            <Pagination
                                currentPage={currentPage}
                                setPage={setPage}
                                totalPages={Math.ceil(results.length / PAGE_SIZE)}
                                setPreviousPage={setPreviousPage}
                                setNextPage={setNextPage}
                                idToScrollOnPageChange="heading-2"
                                nextEnabled={nextEnabled}
                                previousEnabled={previousEnabled}
                            />
                        </div>
                    </>
                    :
                    <div className="text-center text-neutral-400">{t("app.no-search-results")}</div>
            }
        </div>
    )
}

export default SuggestedTours
