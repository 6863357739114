import { Button, LinearProgress } from "@mui/material";
import { getAirportAutocompleteUrl, postReissueSearch } from "api/helpers/api-constants";
import postFetcher, { postParamRequest } from "api/helpers/postFetcher";
import { AirportSearchAutocompleteResult, BookingRoute, FlightOffer, IMissingInputError } from "api/types";
import AutocompleteForFlight from "components/common/HeroSearchForm/AutocompleteForFlight";
import DateInput from "components/common/HeroSearchForm/DateInput";
import ToastNotification from "components/ui/ToastNotification/ToastNotification";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { setIsLoading } from "redux/slices/flightSlice";
import Swal from "sweetalert2";
import ResissueFlightList from "./ReissueFlightList";

interface IReissue {
    route: BookingRoute;
    bookingKey: string;
    onFlightList:(offers:FlightOffer[]) => void;
    tripIndex:number;
}

const ReissueSearchForm: React.FC<IReissue> = ({route, bookingKey, onFlightList, tripIndex}) => {
    const {t} = useTranslation();
    const [ departureAirport, setDepartureAirport] = useState<AirportSearchAutocompleteResult | null>(
      {
      id:route.trips[tripIndex].departurePort,
      text: " "
      } );
    const [ arrivalAirport, setArrivalAirport] = useState<AirportSearchAutocompleteResult | null>(
      {
      id:route.trips[tripIndex].arrivalPort,
      text: " "
      } );;
    const [ selectedDate, setSelectedDate ] = useState<[moment.Moment|null, moment.Moment|null]>([moment(route.trips[tripIndex].departureDate) , moment().add(1,"weeks")])
    const [ missingInputError, setMissingInputError] = useState<IMissingInputError>({
        departureAirport: false,
        arrivalAirport: false,
        departingDate: false,
        returningDate: false,
        departureFirstTrip: false
    });
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [departureFocus, setDepartureFocus] = useState(false);
    const [returnFocus, setReturnFocus] = useState(false);
    const [dateFocus, setDateFocus] = useState(false);
    const [airportChange, setairportChange] = useState<boolean>(false);

    const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
  const handleAirportsChange = () => {
  const temp = departureAirport;
  setDepartureAirport(arrivalAirport);
  setArrivalAirport(temp);
  setairportChange(!airportChange)

};
const [flighList, setflighList] = useState<FlightOffer[]>([]);

const reissueSearch = async () => {
    const payload = {
       bookingKey: bookingKey,
        filekey: route.filekey,
        depPort: departureAirport?.id,
        arrPort: arrivalAirport?.id , 
        depDate: moment(selectedDate[0]).format('YYYY-MM-DD'),
        flightTripIndex: tripIndex,
    }
    setisLoading(true)
    try {
    
      const response = await postParamRequest(postReissueSearch(), payload);
      if (response) {
        setflighList(response.offers)
        onFlightList(response.offers)
        console.log(response);
        if (response.offers.length === 0) {
          setMessage('Farklı rota veya tarih deneyin !'); 
          setSeverity('error');
        }
      } else {
        setMessage('Error'); // Set your success message here
        setSeverity('error');
        throw new Error('Something went wrong on the API server!');
      }
    } catch (error) {
      setMessage('Something went wrong on the API server!'); // Set your success message here
      setSeverity('error');
      console.error(error);
    }
    finally  {
      setisLoading(false)
    }
  };
  const handleSubmit = (e:any) => {
    setflighList([])
    reissueSearch()
  }
  const resetNotification = () => {
    setMessage(null);
    setSeverity('info');
  };
    return (
    
    <div className="border col-span-8 max-h-40 mb-2">
   <h1 className="text-2xl p-1 px-4 w-1/5 font-semibold ">{t("general.search.flight")}</h1>
    <div className="flex   p-2 ">
    
      <AutocompleteForFlight
        placeHolder={t("app.flight-search.airport")}
        desc={t("app.flight-search.from")}
        getUrl={getAirportAutocompleteUrl}
        autocompleteValue={departureAirport}
        setAutocompleteValue={setDepartureAirport}
        localStorageToken="airport-departure"
        error={missingInputError.departureAirport}
        focus={departureFocus}
        autoFocus={departureFocus}
        setFocus={setDepartureFocus}
        setDateFocus={setReturnFocus}
        airportChange={airportChange}
        maxInputValuesLength={1}
      />
     <Button onClick={handleAirportsChange}>
        <FaExchangeAlt />
      </Button>
     
      <AutocompleteForFlight
        placeHolder={t("app.flight-search.airport")}
        desc={t("app.flight-search.to")}
        getUrl={getAirportAutocompleteUrl}
        autocompleteValue={arrivalAirport}
        setAutocompleteValue={setArrivalAirport}
        localStorageToken="airport-arrival"
        error={missingInputError.arrivalAirport}
        autoFocus={returnFocus}
        focus={returnFocus}
        setFocus={setReturnFocus}
        setDateFocus={setDateFocus}
        airportChange={airportChange}
        maxInputValuesLength={1}
      />
       <DateInput
                departureAirport={departureAirport}
                arrivalAirport={arrivalAirport}
                label={t("b2c-app.flights.date")}
                date={selectedDate[0]}
                setDate={(value) => setSelectedDate([value, null])}
                numberOfMonths={ 2}
                dateFocus={dateFocus}
                setDateFocus={setDateFocus}
                error={missingInputError.departureFirstTrip}
                minDate={moment().subtract(1, "days")}
                className={'px-0'}
              />
                         <div
              className={(
                "justify center flex items-center px-4 py-4 lg:py-0"
              )}
            >
             <button
              className="flex h-12 w-full items-center justify-center rounded-lg bg-primary-6000 text-neutral-50 hover:bg-primary-700  focus:ring-1 focus-visible:bg-primary-300 md:h-12 md:w-16"
              onClick={(e) => {
                handleSubmit(e);
                e.currentTarget.blur()
              }}
              id="submit-button" // Add an id to the button
            >
              <span className="mr-3 md:hidden">{t("app.search")}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
            {isLoading && (
          <div className={`absolute top-5 left-0 w-full`}>
            <LinearProgress />
            <span className="flex justify-center text-primary-6000 font-semibold">
              {t("flight.brand.loading")}
            </span>
          </div>
        )}
            </div>
</div>

<ToastNotification message={message} severity={severity} resetNotification={resetNotification} />
</div>
    );
  }
  
  export default ReissueSearchForm;