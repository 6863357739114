import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Button as MuiButton } from '@material-ui/core';
import { getPaymentMethods, postBookingIssuePayment } from 'api/helpers/api-constants';
import fetcher from 'api/helpers/fetcher';
import Button from 'components/ui/Button/Button';
import { postParamRequest } from 'api/helpers/postFetcher';
import PaymentResponseRender from './PaymentResponse';
import currencyFormat from 'utils/currencyFormat';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';

interface PaymentMethod {
  type: string;
  method: string;
  feeRate: number;
}

interface PaymentThreedProps {
  bookingKey: string;
  totalPrice: number
}

const PaymentThreed: React.FC<PaymentThreedProps> = (
  bookingKey,
  totalPrice,
  ) => {
    const { t } = useTranslation();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [pageThreeD, setpageThreeD] = useState<any>(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState<string>('');
  
    const handlePaymentClick = async () => {
      const response = await fetcher(getPaymentMethods());
      if (response) {
        setPaymentMethods(response);
        setOpenDialog(true);
      }
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    const handleSelectPayment = async (selectedPaymentType: string, selectedPaymentMethod?: string) => {
      setSelectedPaymentType(selectedPaymentType);
      setOpenDialog(false);
      setConfirmationDialog(true);
    };
  
    const handleConfirmPayment = async () => {
      setConfirmationDialog(false);
  
      const params = {
        bookingKey: bookingKey.bookingKey,
        paymentType: selectedPaymentType,
        redirectUrl: selectedPaymentType ? window.location.href : '',
      };
  
      const response = await postParamRequest(postBookingIssuePayment(), params);
      if (response) {
        setpageThreeD(response);
      }
    };
  
    const handleCancelPayment = () => {
      setConfirmationDialog(false);
    };
  
  return (
    <div>
      <Button className='bg-primary-6000 text-white' onClick={handlePaymentClick}>
        {t('booking.purchase')}
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <div className='bg-neutral-50'><ButtonClose onClick={handleCloseDialog}/></div>
      <span className='flex justify-center p-4  bg-neutral-50'>{t(
                    "flightbooking.ticketing.please-select-your-preferred-payment-method"
                  )}</span>
        <div className=' bg-neutral-50 p-4 mx-auto  '>
         
        {paymentMethods.length > 0 &&
          paymentMethods.map((method: PaymentMethod, index: number) => (
            <button
            className='p-1'
              key={index}
              onClick={() => {
                handleSelectPayment(
                method.type, method.method  
                )               
              }}
            >
              <div className='grid grid-rows-2 px-4 py-2 border bg-primary-6000 text-white rounded-sm'>
             <span>{method.type}</span>
             <span>{currencyFormat(bookingKey.totalPrice * method.feeRate) }</span> 
              </div>
             
            </button>
          ))}
          </div>
      </Dialog>

      <Dialog open={confirmationDialog} onClose={handleCancelPayment}>
      <div className='bg-neutral-50'><ButtonClose onClick={handleCancelPayment}/></div>
        <span className='flex justify-center p-4 bg-neutral-50'>{t("generals.are-you-sure-you-want-to-proceed")}</span>
        <div className='bg-neutral-50 p-4 flex justify-end space-x-2'>

          <Button className='bg-red-500 text-white' onClick={handleCancelPayment}>{t("b2c-app.generals.cancel")}</Button>
          <Button className='bg-primary-6000 text-white' onClick={handleConfirmPayment}>{t("b2c-app.confirmation-request.confirm")}</Button>
        </div>
      </Dialog>
          {pageThreeD &&  <PaymentResponseRender pageThreeD = {pageThreeD} />}
          </div>
  );
};

export default PaymentThreed;
