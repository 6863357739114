import SectionHeroArchivePage from "components/common/SectionHeroArchivePage";
import useQueryParameters from "hooks/useQueryParameters";
import { Helmet } from "react-helmet";
import { useReduxReducer } from "../../redux/store";
import { SectionHeroHeaderMobile } from "../common/SectionHeroArchivePage/SectionHeroHeaderMobile";
import {ProgressBar} from "primereact/progressbar";
import React from "react";

interface SearchPageWrapperProps {
  title: string;
  className?: string;
  rootClassName?: string;
}

const SearchPageWrapper: React.FC<SearchPageWrapperProps> = ({
  title,
  className,
  rootClassName = "",
  children,
}) => {
  const { isLoading } = useReduxReducer(state => state.general)
  const { isMobile, isApp } = useReduxReducer((state) => state.general);
  return (
    <div
      className="nc-SearchMapPage relative bg-customGray"
      data-nc-id="SearchMapPage"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {/*<BgGlassmorphism />*/}

      {/* SECTION HERO  Just call sectionHeroNobile when searchType Flights, */}
      {!(isMobile || isApp) ? (
          <div className="container py-3">
            <SectionHeroArchivePage/>
          </div>
      ) : (
          <div className="h-auto border-b bg-white">
            <SectionHeroHeaderMobile/>
            { isLoading  && <ProgressBar mode="indeterminate" style={{ height: '3px' }}></ProgressBar> }
          </div>
      )}

      {/* SECTION */}
      <div className={rootClassName}>
        <div className={`${isMobile ? "" : "container mb-12 "} ${className}`}>
          <div className="relative">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SearchPageWrapper;
