import { FC } from "react";
//import SearchGrid from "./SearchGrid";
import SearchPageWrapper from "components/wrappers/SearchPageWrapper";
import OfferList from "./components/OfferList";
import { useReduxReducer } from "redux/store";

const PackageTourSearch: FC = () => {
  const {meta} = useReduxReducer((state)  => state.config)

  return (
    <SearchPageWrapper
      className="pb-24 lg:pb-32 xl:max-w-none"
      title={meta.title}
    >
      <OfferList />
    </SearchPageWrapper>
  );
};

export default PackageTourSearch;
