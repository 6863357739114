import React, { useEffect, useState } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ToastNotificationProps {
  message: string | null;
  severity: AlertColor; // 'error' | 'warning' | 'info' | 'success'
  resetNotification?: () => void;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({ message, severity, resetNotification }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    resetNotification && resetNotification();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastNotification;

// -------------- CALL HINT ---------------
//     const [message, setMessage] = useState<string | null>(null);
//     const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
//     const resetNotification = () => {
//       setMessage(null);
//       setSeverity('info');
//     };

//    <ToastNotification message={message} severity={severity} resetNotification={resetNotification} />
