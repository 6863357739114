import { HotelDetails, OfferPackageTour, SearchPayload } from "api/types";
import StartRating from "components/common/StartRating";
import useQueryParameters from "hooks/useQueryParameters";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoLocationSharp } from "react-icons/io5";
import { AiFillLike } from "react-icons/ai";
import PackageTourQueryParametersService from "services/PackageTourQueryParameterService";
import { FaCheck, FaSun } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Rating } from "@mui/material";
import GallerySlider from "components/common/GallerySlider";
import { isB2B } from "api/helpers/checkUserRole";
import useGetDetails from "api/hooks/useGetDetails";
import { b2cURL, getHotelDetailsUrl } from "api/helpers/api-constants";
import { useReduxReducer } from "redux/store";
import { getUserLanguage } from "api/helpers/fetcher";

interface Props {
  className?: string;
  offer: OfferPackageTour;
  requestKey: string | null;
}

const TourPackageCard = ({ className, offer, requestKey }: Props) => {
  const { t } = useTranslation();
  const queryParameters = useQueryParameters();
  const _isB2B = isB2B();
  const navigate = useNavigate();
  const { isB2C } = useReduxReducer(state => state.general)

  function handleHotelSelect(key: string) {
    if (queryParameters) {
      queryParameters.offerKey = key;
      queryParameters.requestKey = requestKey;
      queryParameters.hotelAtrId = (offer.hotel.hotelAtrId).toString();
      if (queryParameters.offerKey && queryParameters.requestKey) {
        const queryParamString = objectToQueryParamString(queryParameters);

        navigate("/tourpackage/hotel?" + queryParamString);
      }
    }
  }
  const {data} = useGetDetails<HotelDetails>({
    url: offer.hotel.hotelAtrId ? `${isB2C ? b2cURL : ''}${getHotelDetailsUrl((offer.hotel.hotelAtrId).toString(), getUserLanguage())}` : null,
  });
  

  function objectToQueryParamString(obj: any) {
    const queryParams = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (value !== undefined) {
          if (Array.isArray(value)) {
            queryParams.push(
              `${key}=${encodeURIComponent(JSON.stringify(value))}`
            );
          } else if (typeof value === "object") {
            queryParams.push(
              `${key}=${encodeURIComponent(JSON.stringify(value))}`
            );
          } else {
            queryParams.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
      }
    }

    return queryParams.join("&");
  }

  return (
    <div
      className={`group relative h-full overflow-hidden rounded border border-neutral-100 bg-white transition-shadow hover:shadow-xl dark:border-neutral-800 dark:bg-neutral-900 ${className}`}
    >
    <div className="flex h-full flex-col sm:flex-row">
      <div className="relative w-full flex-shrink-0 sm:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-6"
          galleryImgs={data ? data.photos.slice(0,5): [offer.hotel.images[1].url]}
          uniqueID={`stay-${Date.now()}`}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>

        <div className="flex h-full w-full  flex-col  p-2">
          <div className="flex flex-col ">
            <div className="flex items-center justify-between space-x-2">
              <h1 className="text-xl font-medium capitalize">
                <span
                  className="h4 cursor-pointer line-clamp-1"
                  onClick={() => handleHotelSelect(offer.key)}
                >
                  {offer.hotel.hotelName}
                </span>
              </h1>
              <Rating
                name="read-only"
                value={parseInt(offer.hotel.stars)}
                readOnly
              />
            </div>

            <a
              target="_blank"
              href={
                "https://www.google.com/maps?q=" +
                offer.hotel.latitude +
                "," +
                offer.hotel.longitude
              }
              className="-mt-2 mb-2 flex items-center space-x-1 text-[13px] text-neutral-500 hover:text-primary-700 dark:text-neutral-400"
            >
              <IoLocationSharp />
              <span>{offer.hotel.cityName}</span>
            </a>
          </div>

          <div className="mb-2 flex items-center space-x-2">
            <div className="flex h-8 w-20 items-center justify-center space-x-1 rounded bg-primary-6000 text-white">
              <AiFillLike />
              <span className="text-sm font-semibold">
                {offer.hotel.review.reviewRecommendationRate}%
              </span>
            </div>

            <div className="flex h-8 w-20 items-center justify-center space-x-1 rounded border border-primary-6000">
              <FaSun />
              <span className="text-sm font-semibold">
                {offer.hotel.review.reviewPoint}/6
              </span>
            </div>
          </div>

          <div className="divice-x-1 h-100 grid w-full grid-cols-12">
            <div className="col-span-7 flex flex-col  space-y-1">
              {offer.hotel.review.topReviewRatings?.map((text, index) => (
                <div className="flex items-center space-x-1" key={index}>
                  <FaCheck />
                  <span className="text-[14px]">
                    {t("react.tourpackage.topreviews." + text.toLowerCase())}
                  </span>
                </div>
              ))}
            </div>

            <div className="col-span-5 flex flex-col items-end ">
              <span className="text-sm mt-auto font-normal text-neutral-500 dark:text-neutral-400">
                {t("react.hotel.total-price")}
              </span>
              <span className="text-2xl font-semibold">
                {offer.price.totalPrice.toLocaleString("en-US")}{" "}
                <span className="text-lg">{offer.price.currencyCode}</span>
              </span>
              <button
                className="mt-1 rounded bg-primary-6000 px-3 py-1 font-semibold text-white hover:bg-primary-700"
                onClick={() => handleHotelSelect(offer.key)}
              >
                {t("generals.continue")}
              </button>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-grow flex-col p-3 sm:p-5">

        <div className='flex justify-between max-w-[660px]'>
            <div className='flex flex-col w-1/2'>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <h2 className="text-lg font-medium capitalize">
                    <span className="line-clamp-1">{offer.hotel.hotelName}</span>
                  </h2>
                  <Rating name="read-only" value={parseInt(offer.hotel.stars)} readOnly />
                </div>

                <a target='_blank' href={'https://www.google.com/maps?q=' + offer.hotel.latitude + ',' + offer.hotel.longitude} className="flex space-x-2 items-center text-sm text-neutral-500 dark:text-neutral-400 hover:text-primary-700">
                  <IoLocationSharp />
                  <span>
                    {offer.hotel.cityName}
                  </span>
                </a>

              </div>

              <div className="my-4 hidden w-14 border-b border-neutral-100 dark:border-neutral-800 sm:block" />

              <div className='flex items-center space-x-2'>
                <div className='flex items-center justify-center space-x-1 rounded bg-primary-6000 text-white w-20 h-8'>
                  <AiFillLike />
                  <span className='text-sm font-semibold'>{offer.hotel.review.reviewRecommendationRate}%</span>
                </div>

                <div className='flex items-center justify-center space-x-1 rounded border border-primary-6000 w-20 h-8'>
                  <FaSun />
                  <span className='text-sm font-semibold'>{offer.hotel.review.reviewPoint}/6</span>
                </div>
              </div>
            </div>

            <div>
              <div className='space-y-1 pl-10'>
                {offer.hotel.review.topReviewRatings?.map((text, index) => (<div className='flex items-center space-x-1' key={index}>
                  <FaCheck />
                  <span className='text-sm'>{t('react.packagetour.topreviews.' + text)}</span>
                </div>))}
              </div>

              <div className="flex flex-col">
                <span className="text-sm font-normal text-neutral-500 dark:text-neutral-400">
                  {t("react.hotel.total-price")}
                </span>
                <span className='text-2xl font-semibold'>{offer.price.totalPrice.toLocaleString('en-US')} <span className='text-lg'>{offer.price.currencyCode}</span></span>
                <button
                  className='bg-primary-6000 hover:bg-primary-700 text-white font-semibold px-3 py-1 rounded-full mt-1'
                  onClick={() => handleHotelSelect(offer.key)}>
                  {t('generals.continue')}
                </button>
              </div>
            </div>
          </div>

        </div> */}
      </div>
    </div>
  );
};

export default TourPackageCard;
