import { getPackageTourOfferUrl } from "api/helpers/api-constants";
import useQueryParameters from "hooks/useQueryParameters";
import  { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FilterComponent from "./FilterComponent";
import {
  OfferPackageTour,
  ResponsePackageTour,
  TourPackageFilters,
  TourPackageSortOptionsSelections,
} from "api/types";
import postFetcher from "api/helpers/postFetcher";
import TourPackageCard from "./TourPackageCard";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


const OfferList = () => {
  const [sort, setSort] = useState<TourPackageSortOptionsSelections>({
    price: {
        order: "ASC",
        active: false
    },
    star: {
        order: "ASC",
        active: false
    },
    review: {
        order: "DESC", 
        active: false
    }
}); 
  const [filters, setFilters] = useState<TourPackageFilters | null>(null);
  const [offers, setOffers] = useState<OfferPackageTour[]>([]);
  const [requestKey, setRequestKey] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [data, setData] = useState<ResponsePackageTour | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { t } = useTranslation();
  const queryParameters = useQueryParameters();
  const updatedAirportList = 
  queryParameters?.airportList!.map(airport => ({
     ...airport,
     text: airport.text!.toUpperCase(),
     id: airport.id!.toUpperCase(),
     iataCode: airport.iataCode!.toUpperCase(),

   }))
  useEffect(() => {
    if (queryParameters) {
   
      const payload = {
        autocompleteKey: queryParameters.autocompleteKey,
        airportList: updatedAirportList,
        paxList: queryParameters.paxList,
        startDate: queryParameters.startDate,
        endDate: queryParameters.endDate,
        requestKey: queryParameters.requestKey,
        tripDurationAsNights: queryParameters.tripDurationAsNights,
        perPage: perPage ? perPage : 5,
        page: page,
      };

      if (payload) {
        setIsLoading(true);

        postFetcher(getPackageTourOfferUrl(), payload)
          .then((res) => {
            setData(res);
            setFilters(res.filter);
            setOffers(res.offers);
            setRequestKey(res.requestKey);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [queryParameters]);

  useEffect(() => {
    if (queryParameters && requestKey) {
      const payloadWithFilter = {
        autocompleteKey: queryParameters.autocompleteKey,
        airportList: updatedAirportList,
        paxList: queryParameters.paxList,
        startDate: queryParameters.startDate,
        endDate: queryParameters.endDate,
        requestKey: requestKey,
        tripDurationAsNights: queryParameters.tripDurationAsNights,
        perPage: perPage,
        page: page,
        filter: filters,
        sort:sort,
      };

      if (payloadWithFilter) {
        setIsLoading(true);

        postFetcher(getPackageTourOfferUrl(), payloadWithFilter) 
          .then((res) => {
            setData(res);
            setOffers(res.offers);
            if (requestKey !== res.requestKey) setRequestKey(res.requestKey);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [filters, requestKey, page, perPage, sort]);
  useEffect(() => {
    setPage(1)
    setPerPage(20)
  }, [filters]);
  return (
    <div className="mx-auto max-w-screen-xl">
     {  offers ?  <> <div className="grid w-full grid-cols-12 gap-5">
    <div className="col-span-3">
          <FilterComponent
            sort={sort}
            setSort={setSort}
            filters={filters}
            setFilters={setFilters} />
        </div>
        <div className="col-span-9 flex flex-col space-y-3">

            {!isLoading
              ? offers.map((offer, index) => {
                return (
                  <div key={index}>
                    <TourPackageCard
                      offer={offer}
                      // key={index}
                      requestKey={requestKey} />
                  </div>
                );
              })
              : [...Array(5)].map((_, index) => {
                return (
                  <div
                    key={index}
                    className="relative flex h-[250px] overflow-hidden border border-neutral-100 bg-white dark:border-neutral-800 dark:bg-neutral-900 "
                  >
                    <div className="h-[250px] w-[300px] animate-pulse bg-slate-200"></div>

                    <div className="box-border flex h-full w-full flex-col justify-between p-3 sm:p-5">
                      <div className="h-full w-full space-y-2">
                        <div className="h-6 w-full animate-pulse bg-slate-200"></div>
                        <div className="h-4 w-full animate-pulse bg-slate-200"></div>
                      </div>

                      <div className="flex justify-between">
                        <div className="flex space-x-3">
                          <div className="h-8 w-20 animate-pulse bg-slate-200"></div>
                          <div className="h-8 w-20 animate-pulse bg-slate-200"></div>
                        </div>

                        <div className="h-6 w-32 animate-pulse bg-slate-200"></div>
                      </div>

                      <div className="flex justify-between">
                        <div className="mt-12">
                          <div className="flex h-6 w-32 animate-pulse bg-slate-200"></div>
                        </div>

                        <div className="h-6 w-32 animate-pulse bg-slate-200"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="flex space-x-2">
              <button
                className="flex flex-1 items-center justify-center space-x-4 rounded border border-neutral-100 bg-primary-6000 py-2 text-white hover:bg-primary-700 disabled:bg-gray-400"
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
              >
                <IoIosArrowBack />
                <span>{t("react.pagination.previous")}</span>
              </button>
              <button
                className="flex flex-1 items-center justify-center space-x-4 rounded border border-neutral-100 bg-primary-6000 py-2 text-white hover:bg-primary-700 disabled:bg-gray-400"
                onClick={() => setPage(page + 1)}
                disabled={data ? (data!.page >= data!.totalPages): false}
              >
                <span>{t("react.pagination.next")}</span>
                <IoIosArrowForward />
              </button>
              <select
                className="rounded text-center"
                value={perPage}
                onChange={(e) => {
                  setPerPage(Number(e.target.value));
                  setPage(1);
              }}              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
             
            </div>
            <span>total pages : {data && data!.totalPages}</span>
          </div>
       
          </div>
          </> : <div className="flex justify-center">{t("react.hotel.search-error")}</div> }
      
    </div>
  );
};

export default OfferList;
