import { ConfigModel } from "api/types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

// This will be updated when the congfig response updated. Also ConfigMOdel will be updated

const initialState: ConfigModel = {
  aboutUs: null,
  legalPages: null,
  audit: {
    host: "",
    marketingType: ""
  },
  auth: {
    authenticationEnabled: false,
  },
  meta: {
    title: "",
    description: "",
  },
  header: {
    defaultLogo: "",
    goToOldSiteEnabled: false,
    bookingsLinkEnabled: false,
    moreLinksEnabled: false,
    searchEnabled: false,
    notificationListEnabled: false,
    favicon: "",
  },
  footer: {
    title: "",
    chatEnabled: false,
    linksEnabled: false,
  },
  product: {
    paxConnectEnabled: false,
    flight: {
      enabled: false,
    },
    hotel: {
      enabled: false,
    },
    villa: {
      enabled: false,
    },
    carRental: {
      enabled: false,
    },
    transfer: {
      enabled: false,
    },
    tour: {
      enabled: false,
    },
    packageTour: {
      enabled: false,
    },
  },
};


  const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
      setConfig: (state, action: PayloadAction<ConfigModel>) => {
        return { ...state, ...action.payload };
      },
    },
  });

  export const { setConfig } = configSlice.actions;

export default configSlice.reducer