import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BookingStatusModel, IUserData} from "api/types";

interface IGeneralState {
    isMobile: boolean
    isApp: boolean
    isGuest: boolean
    isB2C: boolean
    isLoading: boolean
    isAdmin: boolean
    reservationStatus: BookingStatusModel
    userData: IUserData | null
    switchValue: boolean

}

const initialState: IGeneralState = {
    isMobile: window.innerWidth < 916,
    isApp: false,
    isGuest: false,
    isB2C: false,
    isLoading: false,
    isAdmin: false,
    reservationStatus: "RESERVATION",
    userData: null,
    switchValue: false,


}

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setIsMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
        setIsApp: (state, action: PayloadAction<boolean>) => {
            state.isApp = action.payload;
        },
        setIsGuest: (state, action: PayloadAction<boolean>) => {
            state.isGuest = action.payload;
            state.isB2C = action.payload;
        },
        setIsB2C: (state, action: PayloadAction<boolean>) => {
            state.isB2C = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsAdmin: (state, action: PayloadAction<boolean>) => {
                state.isAdmin = action.payload;
        },
        setReservationStatus: (state, action: PayloadAction<BookingStatusModel>) => {
            state.reservationStatus = action.payload;
        },
        setUserData: (state, action: PayloadAction<IUserData | null>) => {
            state.userData = action.payload;
        },
        setSwitchValue: (state, action: PayloadAction<boolean>) => {
            state.switchValue = action.payload;
        },
    }
})

export const {
    setIsMobile,
    setIsApp,
    setIsGuest,
    setIsB2C,
    setIsLoading,
    setSwitchValue,
    setIsAdmin, // ADMIN meaning agency role 18028 & 17003
    setReservationStatus,
    setUserData
} = generalSlice.actions

export default generalSlice.reducer
