import Select from "components/ui/Select/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { Order, setOrder } from "redux/slices/orderSlice";
import { useSearchPageDispatch, useReduxReducer } from "redux/store";

const OrderSelectInput: React.FC = () => {
  const order = useReduxReducer((state) => state.order);
  const dispatch = useSearchPageDispatch();

  const { t } = useTranslation();

  const handleOrderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as Order;
    dispatch(setOrder(value));
  };

  const labels = {
    [Order.ReccomendedAccomodation]: t("react.hotel.recommended-accommodations"),
    [Order.PriceFromLowest]: t("react.hotel.price-ascending"),
    [Order.PriceFromHighest]: t("react.hotel.price-descending"),
    [Order.StarsFromLowest]: t("react.hotel.stars-ascending"),
    [Order.StarsFromHighest]: t("react.hotel.stars-descending"),
    [Order.HotelAZ]: t("react.hotel.name-ascending"),
    [Order.HotelZA]: t("react.hotel.name-descending"),
  };

  return (
    <div>
      <small className="font-semibold">
        {t("react.hotel.sort-by")}
      </small>
      <div className="relative">
        <Select
          value={order}
          onChange={handleOrderChange}
          className="flex items-center justify-center rounded  border border-neutral-300  text-sm focus:outline-none dark:border-neutral-700"
        >
          {Object.keys(labels).map((key) => (
            <option className="text-base" key={key} value={key}>
              {labels[Number(key) as Order]}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default OrderSelectInput;
