import React from 'react';
import { RootSearchPageState, useReduxReducer } from 'redux/store';
import { FlightBanner } from './components/FlightBanner';
import { HotelBanner } from './components/HotelBanner';
import { RentalcarBanner } from './components/RentalcarBanner';

interface IBannerB2C {

}

export const BannerB2C: React.FC<IBannerB2C> = ({}) => {
  const selectedTabs = useReduxReducer((state: RootSearchPageState) => state.tabs);

  return (
    
    <>
    {selectedTabs.activeTab === "Car Rental" 
    ? <RentalcarBanner/> 
    :  <><FlightBanner /><RentalcarBanner /></>
  }
   </>

  );
};




