import OrderSelectInput from "pages/SearchMapPage/OrderSelectInput";
import React from "react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  numStays?: number;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "Stays in Tokyo",
  numStays,
  subHeading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between ">
      <div id="heading-2" className={`${className} scroll-m-12`}>
      <h2 className="text-2xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span
          className={
            "fst-italic block text-sm  pt-1 text-neutral-500 dark:text-neutral-400"
          }
        >
          {numStays} {t("app.generals.results-found")}
        </span>
      )}
    </div>
   
    <OrderSelectInput />

    </div>
    
  );
};

export default Heading2;
