import React, { FC, ReactNode } from "react";
import imagePng from "images/hero-right2.png";
import HeroSearchForm from "components/common/HeroSearchForm";
import {isB2B} from "../../../api/helpers/checkUserRole";
import { SearchTab } from "api/types";

const _isB2B = isB2B();

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage?: SearchTab;
  currentTab?: SearchTab;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage = "Hotel",
  currentTab = "Hotel",
}) => {
  return (
    <div
      className={`nc-SectionHeroArchivePage relative flex flex-col ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >

     
      {/*  // TODO all commented code must be removed when no longer used
        _isB2B ?
            null
            :
            <div className="flex flex-col lg:flex-row lg:items-center">
              <div className="flex flex-shrink-0 flex-col items-start space-y-6 pb-14 lg:mr-10 lg:w-1/2 lg:space-y-10 lg:pb-64 xl:mr-0 xl:pb-80 xl:pr-14">
                <h2 className="text-4xl font-medium leading-[110%] md:text-5xl xl:text-7xl">
                  Tokyo, Jappan
                </h2>
                <div className="flex items-center text-base text-neutral-500 dark:text-neutral-400 md:text-lg">
                  <i className="las la-map-marked text-2xl" />
                  <span className="ml-2.5">Jappan </span>
                  <span className="mx-5" />
                  {listingType ? (
                      listingType
                  ) : (
                      <>
                        <i className="las la-home text-2xl" />
                        <span className="ml-2.5">112 properties</span>
                      </>
                  )}
                </div>
              </div>
              <div className="flex-grow">
                <img className="w-full" src={imagePng} alt="hero" />
              </div>
            </div>
      */}

      <div className="flow-root w-full">
        <div className="z-10 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
